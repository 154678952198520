import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = ({ data }) => {
  const { html, frontmatter } = data.file.childMarkdownRemark;
  return (
    <Layout>
      <SEO title="Privacy Policy"/>
      <section className="hero is-light">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">{frontmatter.headline}</h1>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="content is-size-7" dangerouslySetInnerHTML={{ __html: html }}/>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query PrivacyQuery {
    file(name: {eq: "privacy"}) {
      childMarkdownRemark {
        frontmatter {
          headline
        }
        html
      }
    }
  }
`

export default PrivacyPage
